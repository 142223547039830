const Attention = () => {
    return (
        <span className='text-danger ms-5'>
            <strong>Внимание! </strong>Эта страница временно не работает, т.к. находится в
            разработке.
        </span>
    );
};

export default Attention;
